<template>
  <div>
    <!-- <div v-if="deviceLoading" class="mt-6 loading">
      <v-progress-linear indeterminate :color="siteColor"></v-progress-linear>
    </div> -->
    <div v-if="checkSensor" class="container container--fluid">
      <template v-if="!sensorExists">
        <p>Checking Sensor</p>
      </template>
      <template v-else>
        <p>Sensor Already Exists</p>
        <v-btn :color="siteColor" dark @click="addAnother(true)">Add New Sensor</v-btn>
      </template>
    </div>
    <div v-else-if="sensorAdded && !user" class="container container--fluid">
      <p>Sensor Added</p>
      <v-btn class="mr-3" :color="siteColor" dark @click="setUserAuthentication(false)">Login</v-btn>
      <v-btn :color="siteColor" dark @click="setUserAuthentication(true)">Create Account</v-btn>
    </div>
    <div v-else-if="sensorAdded" class="container container--fluid">
      <p>Sensor Added</p>
      <v-btn class="mr-3" :color="siteColor" dark to="/">View Sensors</v-btn>
      <v-btn :color="siteColor" dark @click="addAnother(true)">Add Another Sensor</v-btn>
    </div>
    <div v-else-if="authenticate">
      <authenticator class="auth" :authState="auth"></authenticator>
    </div>
    <div class="add-sensor" v-else>
      <v-form v-if="!scan" ref="form" class="pb-5" v-model="valid" lazy-validation>
        <v-container fluid>
          <v-row>
            <v-col cols="12" v-if="!wireless">
              <v-row>
                <v-col sm="6" md="3">
                  <v-switch
                    hide-details
                    class="mt-0"
                    v-model="duel"
                    :disabled="wireless"
                    :label="`Duel Sensor`"
                  ></v-switch>
                </v-col>
                <!-- <v-col sm="6" md="3">
                  <v-switch
                    hide-details
                    class="mt-0"
                    v-model="wireless"
                    :disabled="duel"
                    :label="`Wireless TGate`"
                  ></v-switch>
                </v-col> -->
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
              :color="siteColor"
                :label="duel ? 'Sensor 1 Name' : 'Name'"
                hide-details
                v-model="sensor.name"
                :rules="[v => !!v || 'Name is required']"
                required
                autofocus
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="12" md="6" v-if="!hideValues">
              <v-text-field
                :color="siteColor"
                label="UUID"
                hide-details
                v-model="sensor.uuid"
                :rules="[v => !!v || 'UUID is required']"
                required
              ></v-text-field>
            </v-col> -->
            <!-- <v-col cols="12" md="6" v-if="!hideValues">
              <v-text-field :color="siteColor" label="Email" hide-details v-model="sensor.email"></v-text-field>
            </v-col> -->
            <!-- <v-col cols="12" md="6" v-if="!hideValues">
              <v-text-field
                v-mask="'+1 (###) ###-####'"
                placeholder="+1"
                :color="siteColor"
                label="Phone"
                hide-details
                v-model="sensor.phone"
                type="tel"
              ></v-text-field>
            </v-col> -->
            <v-col cols="12" md="6">
              <v-text-field
                type="number"
                :color="siteColor"
                :label="duel ? 'Sensor 1 Alarm Low (℉)' : 'Alarm Low (℉)'"
                hide-details
                v-model="sensor.low"
                placeholder="30"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                type="number"
                :color="siteColor"
                :label="duel ? 'Sensor 1 Alarm High (℉)' : 'Alarm High (℉)'"
                hide-details
                v-model="sensor.high"
                placeholder="44"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                type="number"
                :color="siteColor"
                :label="duel ? 'Sensor 1 Alarm Duration (Min)' : 'Alarm Duration (Min)'"
                hide-details
                v-model="sensor.duration"
                placeholder="30"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-if="!hideValues"
                :color="siteColor"
                label="App EUI"
                hide-details
                v-model="sensor.joinEui"
                :rules="[v => !!v || 'App EUI is required']"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-if="!hideValues"
                :color="siteColor"
                label="App Key"
                hide-details
                v-model="sensor.appKey"
                :rules="[v => !!v || 'App Key is required']"
                required
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="12" md="6">
              <v-select v-model="defaultLocation" hide-details :items="locations" class="ml-5 pt-0"></v-select>
            </v-col> -->
            <v-col class="mt-2">
              <v-btn v-if="!duel" :loading="btnLoading" :color="siteColor" dark @click="addSensor">Add Sensor</v-btn>
            </v-col>
            <!-- <v-col cols="12" md="6" v-if="!hideValues">
              <v-text-field :color="siteColor" label="Code" hide-details v-model="sensor.code"></v-text-field>
            </v-col> -->
          </v-row>
          <v-row v-if="duel">
            <v-col cols="12" md="6">
              <v-text-field
              :color="siteColor"
                label="Sensor 2 Name"
                hide-details
                v-model="sensor2.name"
                :rules="[v => !!v || 'Name is required']"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                type="number"
                :color="siteColor"
                label="Sensor 2 Alarm Low (℉)"
                hide-details
                v-model="sensor2.low"
                placeholder="30"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                type="number"
                :color="siteColor"
                label="Sensor 2 Alarm High (℉)"
                hide-details
                v-model="sensor2.high"
                placeholder="44"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                type="number"
                :color="siteColor"
                label="Sensor 2 Alarm Duration (Min)"
                hide-details
                v-model="sensor2.duration"
                placeholder="30"
              ></v-text-field>
            </v-col>
            <v-col class="mt-2">
              <v-btn :loading="btnLoading" :color="siteColor" dark @click="addSensor">Add Sensors</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    <qrcode-stream v-else @decode="decode"></qrcode-stream>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { Hub, Logger } from "aws-amplify";
import Authenticator from "@/components/Authenticator";
export default {
  name: "addDevice",
  components: {
    Authenticator
  },
  data() {
    return {
      duel: false,
      sensor: {},
      sensor2: {},
      scan: false,
      deviceLoading: false,
      btnLoading: false,
      valid: true,
      checkSensor: true,
      // addingSensor: false,
      sensorAdded: false,
      sensorExists: false,
      sensorChecked: false,
      scanner: false,
      newSensor: true,
      hideValues: true,
      savedSensor: {},
      savedSensor2: {},
      login: false,
      auth: false,
      authenticate: false,
      wireless: false,
      defaultLocation: ""
    };
  },
  async created() {
    await this.authSetup();
    if (this.$route.params.id) {
      await this.checkSensorDetails(this.$route.params.id)
    }
    else {
      this.checkSensor = false;
      this.scan = true
    }

    if (this.userDetails) {
      this.setUserDetails();
      this.defaultLocation = await this.$store.dispatch("location/allLocations")[0]
    }
    
  },
  watch: {
    userDetails(val) {
      // console.log(val)
      // if (val.phone) {
      //   val.phone = this.formatPhone(val.phone);
      // }
      // this.sensor = val;
    },
    loading(val) {
      this.deviceLoading = val
    }
  },
  computed: {
    ...mapState("user", ["user", "userDetails", "loading"]),
    ...mapState("location", ["locations"]),
    ...mapGetters("user", [
      "isSuper",
      "isDistributor",
      "isStore",
      "isUser",
      "isCustomer",
      "userDistributorName",
      "userStoreName",
      "userId",
    ]),
    filteredPhone() {
      if (this.sensor.phone) {
        return this.sensor.phone.replace(/[() -]+/g, "");
      } else return "";
    },
  },
  methods: {
    async checkSensorDetails(uuid) {
      let sensor = await this.$store.dispatch(
        "sensor/checkSensor",
        uuid
      );
      if (sensor === "sensor exists") {
        this.sensorExists = true;
      }
      else {
        // if (sensor === "add to TTN") this.hideValues = false
        // else if (sensor === "ready") this.sensor.removeStock = true
        this.sensor.uuid = uuid;
        this.checkSensor = false;
        this.sensorChecked = true;
        this.wireless = true
      }
    },
    async decode(val) {
      this.checkSensor = true
      await this.checkSensorDetails(val)
      this.scan = false
    },
    authSetup() {
      const logger = new Logger("My-Logger");
      const listener = async data => {
        switch (data.payload.event) {
          case "signIn":
            let details = await this.$store.dispatch("user/getUserDetails", data.payload.data.username)
            if (details.auth === "customer") {
              this.savedSensor.code = details.code
              this.savedSensor.email = details.email
              this.savedSensor.phone = details.phone
              this.savedSensor2.code = details.code
              this.savedSensor2.email = details.email
              this.savedSensor2.phone = details.phone
            }
            await this.saveSensor(true)
            this.$router.push("/").catch(err => {});
            break;
          case "signUp":
            break;
        }
        // this.$store.commit("user/setLoading", false)
      };
      Hub.listen("auth", listener);
    },
    setUserAuthentication(type) {
      this.auth = type
      this.sensorAdded = false
      this.authenticate = true
      window.scrollTo(0,0)
    },
    addAnother(newSensor = false) {
      if (newSensor) {
        this.sensorAdded = false
        this.scan = true
        if (this.$route.path === "/add" || this.$route.path === "/add/") {
          this.sensorExists = false
          this.checkSensor = false
        }
        else {
          this.$router.push("/add")
        }
      } else {
        this.sensorAdded = false;
      }
    },
    formatPhone(number) {
      return `${number.slice(0, 2)} (${number.slice(2, 5)}) ${number.slice(
        5,
        8
      )}-${number.slice(8)}`;
    },
    setUserDetails() {
      this.sensor.code = this.userDetails.code;
      this.sensor.email = this.userDetails.email;
      this.sensor.phone = this.userDetails.phone;
      this.sensor2.code = this.userDetails.code;
      this.sensor2.email = this.userDetails.email;
      this.sensor2.phone = this.userDetails.phone;
    },
    codeArrived(code) {
      this.scanner = false;
      let [uuid, parts] = code.split("P");
      let [_, number] = parts.split("#");
      this.sensor.uuid = uuid.trim();
      this.sensor.partNumber = number.trim();
      this.sensor2.uuid = uuid.trim();
      this.sensor2.partNumber = number.trim();
    },
    startScanner() {
      this.scanner = true;
      this.newSensor = true;
    },
    async saveSensor(add = false) {
      let newSensor;
      if (this.duel) {
        this.savedSensor.duel = true
        this.savedSensor2.duel = true
        let sensor1 = JSON.parse(JSON.stringify(this.savedSensor))
        sensor1.duelId = "1"
        let sensor2 = JSON.parse(JSON.stringify(this.savedSensor2))
        sensor2.duelId = "2"
        let addSensor1 = this.$store.dispatch("sensor/addSensor", sensor1)
        let addSensor2 = this.$store.dispatch("sensor/addSensor", sensor2)
        let res = await Promise.all([addSensor1, addSensor2])
        newSensor = res[0]
      }
      else {
        newSensor = await this.$store.dispatch("sensor/addSensor", this.savedSensor);
      }
      if (newSensor === "code not found") {
        // console.log("incorrect code");
      }
      else if (newSensor === "error adding to TTN") {
        // console.log("error adding to TTN")
      } 
      else if (newSensor) {
        if (!add) {
          this.sensor = {};
          this.sensor2 = {};
          this.sensorAdded = true;
          this.checkSensor = false;
        }
      }
    },
    async addSensor() {
      if (!this.$refs.form.validate()) return;
      // this.deviceLoading = true;
      if (!this.sensorChecked) {
        let sensor = await this.$store.dispatch(
          "sensor/getSingleSensor",
          this.sensor.uuid.trim()
        );
        if (sensor) {
          this.deviceLoading = false;
          this.sensorExists = true;
          this.checkSensor = true;
          return;
        }
      }
      this.savedSensor = {
        uuid: this.sensor.uuid.trim(),
        name: this.sensor.name,
        code: this.sensor.code ? this.sensor.code : null,
        email: this.sensor.email ? this.sensor.email : null,
        phone: this.filteredPhone.length === 12 ? this.filteredPhone : null,
        high: this.sensor.high ? Number(this.sensor.high) : 44,
        low: this.sensor.low ? Number(this.sensor.low) : 30,
        duration: this.sensor.duration ? Number(this.sensor.duration) : 30,
        joinEui: this.sensor.joinEui ? this.sensor.joinEui : null,
        appKey: this.sensor.appKey ? this.sensor.appKey : null,
        removeStock: this.sensor.removeStock ? true : false,
        // raw: this.hideValues ? true : false
        raw: this.wireless ? false : true
      }
      // this.savedSensor2 = {
      //   uuid: this.sensor2.uuid.trim(),
      //   name: this.sensor2.name,
      //   code: this.sensor2.code ? this.sensor2.code : null,
      //   email: this.sensor2.email ? this.sensor2.email : null,
      //   phone: this.filteredPhone.length === 12 ? this.filteredPhone : null,
      //   high: this.sensor2.high ? Number(this.sensor2.high) : 44,
      //   low: this.sensor2.low ? Number(this.sensor2.low) : 30,
      //   duration: this.sensor2.duration ? Number(this.sensor2.duration) : 30,
      //   // raw: this.hideValues ? true : false
      //   raw: true
      // }
      if (!this.user) {
        this.sensorAdded = true;
      }
      else {
        this.btnLoading = true
        await this.saveSensor()
        this.btnLoading = false
      }
      
      // this.deviceLoading = false;
    },
  },
  beforeDestroy() {
    if (this.loading) {
      this.$store.commit("user/setLoading", false)
    }
  }
};
</script>

<style lang="scss">
.loading {
  width: 60%;
  margin: 0 auto;
}
:root {
  --amazonOrange: #fa712a;
  --lightAmazonOrange: #fa712a;
  --darkAmazonOrange: #fa712a;
}
.auth {
  text-align: center;
  .error {
    color: #ff5252 !important;
    background-color: #fff !important;
    font-size: 0.9rem;
  }
  @media (max-width: 599px) {
    & > div {
      min-width: 0;
    }
  }
}
</style>